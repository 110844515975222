import { IFaq, IQuestion } from '@lstv/core/types/IFaq';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import Typography from '~/atoms/Typography';
import { CircleMinus, CirclePlus } from '~/components/InlineSVG';
import * as globals from '~/globals';
import * as S from './index.styles';

export const FaqQuestion = ({ question, id, newStyle }: { question: IQuestion; id: string; newStyle?: boolean }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <S.FaqQuestionContainer $newStyle={newStyle} onClick={() => setExpanded(!expanded)}>
      <S.QuestionTarget>
        <Typography variant="h5" fontWeight="600">
          {question.q}
        </Typography>
        <div style={{ width: 35, height: 35, flexShrink: 0, marginLeft: '10px' }}>
          {expanded ? <CircleMinus /> : <CirclePlus />}
        </div>
      </S.QuestionTarget>

      <AnimateHeight id={id} duration={globals.SIDEBAR_ACCORDION_SPEED} height={expanded ? 'auto' : 0} animateOpacity>
        <S.Answer>{parse(question.a)}</S.Answer>
      </AnimateHeight>
    </S.FaqQuestionContainer>
  );
};

const FaqQuestions = ({
  faqs,
  selected,
  newStyle,
  ...rest
}: { faqs: IFaq[]; selected: string; newStyle?: boolean } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...rest}>
      {faqs.map((faq) => (
        <S.QuestionSet key={faq.id} selected={faq.id === selected}>
          {faq.questions.map((question, index) => (
            <FaqQuestion key={index} id={`q${index}`} {...{ question, newStyle }} />
          ))}
        </S.QuestionSet>
      ))}
    </div>
  );
};

export default FaqQuestions;
