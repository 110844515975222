import styled from 'styled-components';
import theme from '~/styles/styledComponentsTheme';

type QSProps = {
  selected?: boolean;
};

export const FaqQuestionContainer = styled.div<{ $newStyle?: boolean }>`
  padding: 15px 0;
  ${(props) => !props.$newStyle && `border-bottom: 1px solid ${props.theme.midGrey};`}
  ${(props) => props.$newStyle && `background: ${props.theme.grey_5_0}; border-radius: 10px; padding: 30px 20px;`}
  cursor: pointer;
`;

export const QuestionSet = styled.div<QSProps>`
  display: ${(props) => (props.selected ? 'block' : 'none')};
`;

export const QuestionTarget = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Answer = styled.div`
  padding-top: 15px;
  margin-right: 40px;
  white-space: pre-wrap;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${theme.breakpoints.tablet} {
    padding-top: 5px;
  }
`;
