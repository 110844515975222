import { ReactNode } from 'react';
import styled from 'styled-components';
import { UserDevice } from '~/globals';
import breakpoints from '~/globals/breakpoints';
import PageHeaderLayout from './PageHeaderLayout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  @media ${UserDevice.tablet} {
    margin-top: 30px;
  }
  margin-bottom: 70px;
`;

const Contents = styled.div<{ wide?: boolean; bottomSeparator?: boolean; bottomMargin?: number }>`
  border-top: ${(props) => (props.bottomSeparator ? '1px solid #e6e6e6' : 'none')};
  padding-top: ${(props) => (props.bottomSeparator ? `${10}px` : '0px')};
  width: ${(props) => (props.wide ? '100%' : '60%')};
  padding-bottom: 100px;

  @media ${breakpoints.isMobile} {
    width: 100%;
    padding-bottom: 75px;
  }
`;

type Props = {
  children: ReactNode | ReactNode[];
  wideContent?: boolean;
  headerImage?: string;
  headerText: string;
  headerSubtitle?: string;
  bottomSeparator?: boolean;
  parentLabel?: string;
  parentUrl?: string
};

const StaticPageLayout = ({
  children,
  wideContent,
  headerImage,
  headerText,
  headerSubtitle,
  bottomSeparator,
  parentLabel,
  parentUrl
}: Props) => {
  return (
    <PageHeaderLayout
      image={headerImage}
      title={headerText}
      description={headerSubtitle ?? ''}
      breadcrumbsData={{ currentPageLabel: headerText, parentLabel: parentLabel, parentUrl: parentUrl }}
    >
      <Container>
        <Contents bottomSeparator={bottomSeparator} wide={wideContent}>
          {children}
        </Contents>
      </Container>
    </PageHeaderLayout>
  );
};

export default StaticPageLayout;
