import { useCallback } from 'react';
import { faArrowCircleLeft, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useModals } from '~/hooks/useModals';
import { isClient } from '~/utils/ssr';
import LSTVButton from '~/components/LSTVButton';
import LSTVLink from '~/components/LSTVLink';

const spacing = '5px';

export interface BreadcrumbsData {
  // We'll also need `path: {label: string, url: string}[]`.
  /**
   * Label describing current page, used as last element of the breadcrumbs.
   */
  currentPageLabel: string;
  // TODO: once there will occur use case to add more nestings, let's refactor it to use []
  parentLabel?: string;
  parentUrl?: string;
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  font-size: 15px;
  line-height: 18px;
`;

const StyledButton = styled(LSTVButton)`
  height: 36px;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  line-height: inherit;
`;

const StyledLink = styled(LSTVLink)`
  color: inherit !important;
  text-decoration: none;
  align-items: center;
  margin-right: ${spacing};

  &:hover {
    text-decoration: underline;
  }
`;

const SlashContainer = styled.span`
  margin-right: ${spacing};
`;

const CurrentPageLabelContainer = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.highlight_7_0};
`;

const Breadcrumbs = ({ data }: { data: BreadcrumbsData }) => {
  const { openShareModal } = useModals();
  const { currentPageLabel, parentLabel, parentUrl } = data;
  const client = isClient();

  const onShareClick = useCallback(() => {
    openShareModal({
      title: currentPageLabel,
      url: client ? window.location.href : undefined,
    });
  }, [currentPageLabel, client]);

  return (
    <Container>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <StyledButton
          onClick={() => {
            history.back();
          }}
        >
          <FontAwesomeIcon style={{ fontSize: '18px' }} icon={faArrowCircleLeft} />
          <div style={{ width: '12px' }} />
        </StyledButton>
        <div style={{ marginRight: '12px', paddingTop: '9px' }}>
          <StyledLink to="/">Home</StyledLink>
          <SlashContainer>/</SlashContainer>
          {parentLabel && parentUrl && (
            <>
              <StyledLink to={`/${parentUrl}`}>{parentLabel}</StyledLink>
              <SlashContainer>/</SlashContainer>
            </>
          )}
          <CurrentPageLabelContainer>{currentPageLabel}</CurrentPageLabelContainer>
        </div>
      </div>
      <StyledButton onClick={onShareClick}>
        <div style={{ alignSelf: 'flex-start', paddingTop: '9px' }}>Share</div>
        <FontAwesomeIcon style={{ fontSize: '18px', marginLeft: '9px' }} icon={faExternalLink} />
      </StyledButton>
    </Container>
  );
};

export default Breadcrumbs;
