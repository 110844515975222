import styled from 'styled-components';
import Typography from '~/atoms/Typography';
import Breadcrumbs, { BreadcrumbsData } from '~/components/Breadcrumbs';
import { maxWidth } from '~/styles/media';

export interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  title?: string;
  description?: string;
  breadcrumbsData: BreadcrumbsData;
}

export const getDescriptionFromTitle = (title: string) =>
  `Browse and discover ${title} for your wedding. Search by location to find the perfect pros for you.`;

export const getDescriptionFromTemplate = (template: string, location?: string, numberInCollection?: number) =>
  template.replace('LOCATION', location || 'any location').replace('NUMBER', numberInCollection?.toString() || '');

const Container = styled.div`
  @media ${maxWidth('md')} {
    padding-top: 10px;
  }
`;

const PageHeader = ({ image, title, description, breadcrumbsData, ...rest }: PageHeaderProps) => {
  const content = (
    <>
      {title && (
        <Typography variant="h1" marginTop="20px" customStyles="padding-bottom: 0px;">
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="h5" marginTop="18px" marginBottom="20px">
          {description}
        </Typography>
      )}
    </>
  );
  return (
    <Container {...rest}>
      <Breadcrumbs data={breadcrumbsData} />
      {image ? (
        <div style={{ display: 'flex' }}>
          <img src={image} style={{ width: 110, marginRight: 30 }} />
          <div>{content}</div>
        </div>
      ) : (
        <>{content}</>
      )}
    </Container>
  );
};

export default PageHeader;
