import styled from 'styled-components';
import breakpoints from '~/globals/breakpoints';
import PageHeader, { PageHeaderProps } from '../PageHeader';

interface Props extends PageHeaderProps {
  children: React.ReactNode;
}

const StyledHeader = styled(PageHeader)`
  margin: 0 16px;

  @media ${breakpoints.laptop} {
    margin: 0 46px;
  }
`;

const ContentContainer = styled.div`
  margin: 0 16px;

  @media ${breakpoints.laptop} {
    margin: 0 46px;
  }
`;

/**
 * A layout with a header + content passed as `children`. Adds horizontal margins to content.
 */
const PageHeaderLayout = ({ children, ...headerProps }: Props) => (
  <>
    <StyledHeader {...headerProps} />
    <ContentContainer>{children}</ContentContainer>
  </>
);

export default PageHeaderLayout;
